import React from "react"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"

import FaqLayout from "../../components/faq-layout"

export default () => (
  <FaqLayout subMenuActivo="cobertura">
    <Accordion defaultActiveKey="0">
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0">
          <h6>
            <a className="collapsed" href="#a" data-toggle="collapse">
              ¿Qué coberturas puedo contratar en Web del Seguro?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <p>
              Podés contratar todas las coberturas ofrecidas por las distintas aseguradoras y que
              fueron autorizadas por la Superintendencia de Seguros de la Nación tales como:
            </p>

            <h6 className="h6 font-weight-medium">Responsabilidad Civil</h6>
            <ul className="mb-3">
              <li>Cobertura del Mercosur</li>
              <li>+ otros</li>
            </ul>

            <h6 className="h6 font-weight-medium">Terceros Básico</h6>
            <ul className="mb-3">
              <li>Responsabilidad Civil</li>
              <li>Cobertura del Mercosur</li>
              <li>Auxilio</li>
              <li>Robo, incendio, rotura total</li>
              <li>+ otros</li>
            </ul>

            <h6 className="h6 font-weight-medium">Terceros Completo</h6>
            <ul className="mb-3">
              <li>Responsabilidad Civil</li>
              <li>Cobertura del Mercosur</li>
              <li>Auxilio</li>
              <li>Robo, incendio, rotura total</li>
              <li>Robo, incendio parcial</li>
              <li>Cobertura de cristales</li>
              <li>Cobertura de granizo</li>
              <li>Cobertura de cerraduras</li>
              <li>+ otros</li>
            </ul>

            <h6 className="h6 font-weight-medium">Todo Riesgo</h6>
            <ul className="mb-3">
              <li>Responsabilidad Civil</li>
              <li>Cobertura del Mercosur</li>
              <li>Auxilio</li>
              <li>Robo, incendio, rotura total</li>
              <li>Robo, incendio parcial</li>
              <li>Rotura parcial según franquicia</li>
              <li>Cobertura de cristales plus</li>
              <li>Cobertura de cerraduras</li>
              <li>Cobertura de granizo</li>
              <li>+ otros</li>
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="1">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              ¿Estoy cubierto, aunque no haya pagado la primera cuota de la póliza?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <p>
              Estás cubierto desde el primer momento en que contratas la póliza hasta las 00:00 hs.
              de la fecha de vencimiento de la primera cuota, debido que la vigencia se respetará
              independientemente de tener la primera cuota pagada.
            </p>
            <p>
              De igual manera, las cuotas de tu seguro se facturarán siempre dentro de la vigencia
              de la póliza. Lo que implica que, en el segundo mes de vigencia, se cobre la cuota
              pertinente al primer mes de cobertura.
            </p>
            <p>
              Esto depende del procesamiento que realizan las tarjetas de crédito y los bancos para
              los CBU/descuentos en cuenta.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="2">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              ¿Si empiezo a pagar un mes más tarde del comienzo de la vigencia, ¿igual estoy
              cubierto?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            <p>
              Por supuesto que sí. El débito puede ingresar más tarde, pero eso no quiere decir que
              no estés cubierto. Igualmente te recomendamos estar pendiente al ingreso del débito en
              tu tarjeta o a la llegada del resumen de cuenta a tu domicilio, de acuerdo con la
              modalidad de pago seleccionada.
            </p>
            <p>
              En el caso de pago por CBU, la cuenta debe tener fondos para poder efectuar el débito
              de la cuota, para estar cubierto.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="3">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              ¿Si me roban el auto y aún no terminé de pagar todas las cuotas, ¿tendré que pagar el
              total del premio de la póliza?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            <p>
              Si se tiene contratada una póliza de vigencia mensual, sólo se necesitará pagar hasta
              el mes en el cual ocurrió el siniestro, no necesitas pagar la totalidad de la
              anualidad.
            </p>
            <p>
              En caso de contratar una póliza de vigencia, trimestral, cuatrimestral, semestral o
              anual, requerirás pagar desde la fecha de sucedido el siniestro, las cuotas adeudadas
              hasta la terminación del contrato.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="4">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              ¿Es necesario informar si no quiero renovar la póliza?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="4">
          <Card.Body>
            <p>
              La póliza se renovará de forma automática. En caso de que no quieras renovarla, se te
              pedirá que por favor nos lo informes mediante correo.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="5">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              Si no aviso, ¿la póliza se renovará automáticamente?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="5">
          <Card.Body>
            <p>
              Sí. tenemos un sistema de renovación automática, por el cual, si no te comunicas con
              nosotros, te mandaremos tu nueva póliza.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="6">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              ¿Qué valor me pagan ante robo o destrucción total cubierto por mi póliza?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="6">
          <Card.Body>
            <p>
              Es importante que verifiques en la póliza la cláusula que define el valor a cancelar
              en caso de robo o destrucción del vehículo (valor asegurado o valor de reposición).
            </p>
            <p>
              Si el monto acordado en la póliza es “valor de reposición”, al momento del siniestro
              la aseguradora tomará como valor del vehículo el valor de venta al contado en plaza de
              un vehículo de igual marca, modelo, año y características, más los impuestos, tasas y
              contribuciones que pueden corresponder.
            </p>
            <p>
              En ningún caso se podrá percibir una indemnización o monto superior al daño sufrido,
              con prescindencia del valor de la suma asegurada consignada en el frente de la póliza.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </FaqLayout>
)
